import 'vanilla-cookieconsent/dist/cookieconsent.js';
import '@orestbida/iframemanager/dist/iframemanager.js';
import { deleteCookie } from './cookie-helper';

const cc = initCookieConsent();
const manager = iframemanager();

// document.body.classList.toggle('c_darkmode');

manager.run({
  currLang: document.documentElement.getAttribute('lang') ?? 'de',
  onChange: ({ changedServices, eventSource }) => {
    if (eventSource.type === 'click') {
      cc.accept(changedServices);
    }
  },
  services: {
    youtube: {
      embedUrl: 'https://www.youtube-nocookie.com/embed/{data-id}',
      thumbnailUrl: 'https://i3.ytimg.com/vi/{data-id}/hqdefault.jpg',
      iframe: {
        allow: 'accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen;',
      },
      cookie: {
        name: 'cc_youtube',
      },
      languages: {
        de: {
          notice:
            'Dieser Inhalt wird von einem externen Anbieter gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die <a rel="noreferrer" href="https://www.youtube.com/t/terms" title="Bedingungen und Konditionen" target="_blank">Bedingungen und Konditionen</a> von youtube.com.',
          loadBtn: 'Video laden',
          loadAllBtn: 'Nicht nochmal fragen',
        },
      },
    },
    vimeo: {
      embedUrl: 'https://player.vimeo.com/video/{data-id}',
      thumbnailUrl: async (dataId, setThumbnail) => {
        const url = `https://vimeo.com/api/v2/video/${dataId}.json`;
        const response = await (await fetch(url)).json();
        const thumbnailUrl = response[0]?.thumbnail_large;
        thumbnailUrl && setThumbnail(thumbnailUrl);
      },
      iframe: {
        allow: 'accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen;',
      },
      cookie: {
        name: 'cc_vimeo',
      },
      languages: {
        de: {
          notice:
            'Dieser Inhalt wird von einem externen Anbieter gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die <a rel="noreferrer noopener" href="https://vimeo.com/terms" target="_blank">Bedingungen und Konditionen</a> von vimeo.com.',
          loadBtn: 'Video laden',
          loadAllBtn: 'Nicht nochmal fragen',
        },
      },
    },
  },
});

cc.run({
  current_lang: document.documentElement.getAttribute('lang'),
  autoclear_cookies: true,
  page_scripts: true,
  cookie_expiration: 365,
  remove_cookie_tables: true,
  gui_options: {
    consent_modal: {
      layout: 'cloud', // box,cloud,bar
      position: 'bottom center', // bottom,middle,top + left,right,center
      transition: 'slide', // zoom,slide
    },
    settings_modal: {
      layout: 'box', // box,bar
      position: 'left', // right,left (available only if bar layout selected)
      transition: 'slide', // zoom,slide
    },
  },

  onAccept: function () {
    if (cc.allowedCategory('vimeo')) {
      manager.acceptService('vimeo');
    }
    if (cc.allowedCategory('youtube')) {
      manager.acceptService('youtube');
    }
  },

  onChange: function () {
    if (!cc.allowedCategory('vimeo')) {
      manager.rejectService('vimeo');
      deleteCookie('vimeo');
    }
    if (!cc.allowedCategory('youtube')) {
      manager.rejectService('youtube');
      deleteCookie('youtube');
    }
  },

  categories: {
    analytics: {
      readOnly: false,
      autoClear: {
        cookies: [
          {
            name: /^(_ga)/,
            domain: 'plu.de',
          },
          {
            name: '_gid', //string
            domain: 'plu.de',
          },
        ],
      },
    },
  },

  languages: {
    en: {
      consent_modal: {
        title: "Hello traveller, it's cookie time!!",
        description:
          'In order to design our website optimally for you and to continuously improve it, as well as to display content according to your interests, we use cookies. <button type="button" data-cc="c-settings" class="cc-link">Settings</button>',
        primary_btn: {
          text: 'Accept all',
          role: 'accept_all', //'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Reject all',
          role: 'accept_necessary', //'settings' or 'accept_necessary'
        },
        revision_message:
          '<br><br>Dear user, terms and conditions have changed since the last time you visisted!',
      },
      settings_modal: {
        title: 'Cookie settings',
        save_settings_btn: 'Save settings',
        accept_all_btn: 'Accept all',
        reject_all_btn: 'Reject all',
        close_btn_label: 'Close',
        cookie_table_headers: [
          { col1: 'Name' },
          { col2: 'Domain' },
          { col3: 'Expiration' },
          { col4: 'Description' },
        ],
        blocks: [
          {
            title: 'Cookie usage 📢',
            description:
              'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. For more details relative to cookies and other sensitive data, please read the full <a href="/en/privacy-policy" class="cc-link">privacy policy</a>.',
          },
          {
            title: 'Strictly necessary cookies',
            description: 'These cookies are essential for the proper functioning of my website.',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true, //cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          {
            title: 'Google Analytics',
            description:
              'We use Google Analytics, a web analytics service from Google that uses cookies to collect and analyze anonymized information about the use of our website. This helps us to better understand user behavior.',
            toggle: {
              value: 'analytics',
              enabled: false,
              readonly: false,
            },
            // cookie_table: {
            //   body: [
            //     {
            //       name: '_ga',
            //       domain: '.webworker.me',
            //       desc: '',
            //     },
            //     {
            //       name: '_gid',
            //       domain: '.webworker.me',
            //       desc: '',
            //     },
            //   ],
            // },
          },
          {
            title: 'Youtube',
            description:
              'Youtube uses cookies to enable video playback and interaction with the video player and also collects personal data such as IP addresses.',
            toggle: {
              value: 'youtube',
              enabled: false,
              readonly: false,
            },
          },
          {
            title: 'Vimeo',
            description:
              'Vimeo uses cookies to enable video playback and interaction with the video player and also collects personal data such as IP addresses.',
            toggle: {
              value: 'vimeo',
              enabled: false,
              readonly: false,
            },
          },
          {
            title: 'More information',
            description:
              'To learn more, please read our <a href="/en/privacy-policy" class="cc-link">privacy policy</a>.',
          },
        ],
      },
    },

    de: {
      consent_modal: {
        title: "Hello traveller, it's cookie time!!",
        description:
          'Um unsere Webseite für Sie optimal zu gestalten und fortlaufend zu verbessern sowie zur interessengerechten Ausspielung von Inhalten, verwenden wir Cookies. <button type="button" data-cc="c-settings" class="cc-link">Einstellungen</button>',
        primary_btn: {
          text: 'Alle akzeptieren',
          role: 'accept_all', //'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Alle ablehnen',
          role: 'accept_necessary', //'settings' or 'accept_necessary'
        },
        revision_message:
          '<br><br>Dear user, terms and conditions have changed since the last time you visisted!',
      },
      settings_modal: {
        title: 'Cookie Einstellungen',
        save_settings_btn: 'Einstellungen speichern',
        accept_all_btn: 'Alle akzeptieren',
        reject_all_btn: 'Alle ablehnen',
        close_btn_label: 'Schließen',
        cookie_table_headers: [
          { col1: 'Name' },
          { col2: 'Domain' },
          { col3: 'Expiration' },
          { col4: 'Description' },
        ],
        blocks: [
          {
            title: 'Cookie Nutzung 📢',
            description:
              'Hier können Sie einsehen und anpassen, welche Information wir über Sie speichern. Um mehr zu erfahren, lesen Sie bitte unsere <a href="/datenschutz" class="cc-link">Datenschutzerklärung</a>.',
          },
          {
            title: 'Technisch notwendige Cookies',
            description: 'Notwendige Cookies die gebraucht werden um die Webseite zu benutzen.',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true, //cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          {
            title: 'Google Analytics',
            description:
              'Wir verwenden Google Analytics, einen Webanalysedienst von Google, der Cookies einsetzt, um anonymisierte Informationen über die Nutzung unserer Website zu sammeln und auszuwerten. Dies hilft uns, das Nutzerverhalten besser zu verstehen.',
            toggle: {
              value: 'analytics',
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              {
                col1: '^_ga',
                is_regex: true,
              },
              {
                col1: '_gid',
              },
            ],
          },
          {
            title: 'Youtube',
            description:
              'Youtube verwendet Cookies, um die Video-Wiedergabe und Interaktion mit dem Video-Player zu ermöglichen und erfasst dabei auch personenbezogene Daten wie IP-Adressen.',
            toggle: {
              value: 'youtube',
              enabled: false,
              readonly: false,
            },
          },
          {
            title: 'Vimeo',
            description:
              'Vimeo verwendet Cookies, um die Video-Wiedergabe und Interaktion mit dem Video-Player zu ermöglichen und erfasst dabei auch personenbezogene Daten wie IP-Adressen.',
            toggle: {
              value: 'vimeo',
              enabled: false,
              readonly: false,
            },
          },
          {
            title: 'Weitere Informationen',
            description:
              'Um mehr zu erfahren, lesen Sie bitte unsere <a class="cc-link" href="/datenschutz">Datenschutzerklärung</a>.',
          },
        ],
      },
    },
  },
});
