import "../css/app.css";
import "lazysizes";
import Alpine from "alpinejs";
import Splide, { CLASS_PAGINATION } from "@splidejs/splide";
import "./cookie.js";

window.Alpine = Alpine;

Alpine.data("sugarDsgvo", () => ({
  loading: false,
  validate: false,
  messageError: "",
  messageSuccess: "",
  showModal: false,
  form: {
    state: "",
    speech: "",
    candidate_av_comment_c: "",
    notice_period_c: "",
    delete: false,
    candidateID: null,
    CRAFT_CSRF_TOKEN: null,
  },
  init: function () {
    this.form.CRAFT_CSRF_TOKEN = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    this.form.candidateID = this.getIdFromUrl("cid");
    this.$watch("form.delete", (value) => {
      if (value === true) {
        this.form.state = "";
        this.form.candidate_av_comment_c = "";
        this.form.notice_period_c = "";
        this.form.speech = "";
      }
    });
  },
  getIdFromUrl: function (name) {
    var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
  },
  checkDelete: function () {
    if (this.form.delete === false) {
      this.showModal = true;
    }
  },
  resetForm: function () {
    this.validate = false;
    this.messageError = "";
    this.form = {
      state: "",
      speech: "",
      candidate_av_comment_c: "",
      notice_period_c: "",
      delete: false,
      candidateID: null,
    };
  },
  validateForm: function () {
    if (
      (this.form.state === "" || this.form.speech === "") &&
      this.form.delete === false
    ) {
      return false;
    }
    return true;
  },
  submitForm: function () {
    this.validate = true;
    var formData = JSON.parse(JSON.stringify(this.form));
    console.log("Zu sendende Daten:", formData);

    // Validierung der erforderlichen Felder
    if (!this.form.CRAFT_CSRF_TOKEN) {
      console.error("CSRF-Token fehlt!");
      return;
    }
    if (!this.form.candidateID) {
      console.error("Candidate ID fehlt!");
      return;
    }

    // Wenn die Validierung erfolgreich ist
    if (this.validateForm()) {
      this.loading = true;
      var that = this;
      var xhr = new XMLHttpRequest();

      // Anfrage konfigurieren
      xhr.open("POST", "/actions/sugar/dsgvo/form", true);
      xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");

      // Anfrage abschließen
      xhr.onload = function () {
        that.loading = false;
        let data;
        console.log("XHR Status:", xhr.status);
        try {
          if (xhr.status === 200) {
            data = JSON.parse(xhr.responseText);
            // console.log("Data Antwort:", data);
            if (data.status === "500") {
              that.messageError =
                "Es tut uns leid, aber das Absenden des Formulars hat nicht geklappt. Versuchen Sie es noch einmal oder kontaktieren Sie uns unter +49 (0) 89 2323 9169-0 oder castings@plu.de. Wir sind für Sie da!";
            } else {
              that.messageSuccess = that.form.delete
                ? "Vielen Dank für Ihr Feedback! Wir kommen Ihrem Wunsch nach und löschen Ihre Daten. Wenn Sie Fragen haben, sind wir für Sie da unter +49 (0) 89 2323 9169-0 oder castings@plu.de."
                : "Vielen Dank für Ihr Feedback! Wir haben Ihre Daten und die Speicherung direkt aktualisiert. Wenn Sie Fragen haben, sind wir für Sie da unter +49 (0) 89 2323 9169-0 oder castings@plu.de.";
              that.resetForm();
            }
          } else {
            that.messageError =
              "Es tut uns leid, aber das Absenden des Formulars mit der CandidateID " +
              that.form.candidateID +
              " hat nicht geklappt. Versuchen Sie es noch einmal oder kontaktieren Sie uns unter +49 (0) 89 2323 9169-0 oder castings@plu.de. Wir sind für Sie da!";
          }
        } catch (e) {
          console.error("Fehler beim Verarbeiten der Server-Antwort:", e);
          that.messageError =
            "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie uns.";
        }
      };

      xhr.onerror = function () {
        that.loading = false;
        console.error("Netzwerkfehler bei der Anfrage.");
        that.messageError =
          "Ein Netzwerkfehler ist aufgetreten. Bitte überprüfen Sie Ihre Verbindung oder versuchen Sie es später erneut.";
      };

      xhr.send(JSON.stringify(formData));
    }
  },
}));

Alpine.start();

// Splide
document.addEventListener("DOMContentLoaded", function () {
  const galleries = document.getElementsByClassName("splide-gallery");
  for (let i = 0; i < galleries.length; i++) {
    new Splide(galleries[i], {
      lazyLoad: "nearby",
    }).mount();
  }
  const logos = document.getElementsByClassName("splide-logos");
  for (let i = 0; i < logos.length; i++) {
    new Splide(logos[i], {
      lazyLoad: "nearby",
      perPage: 5,
      gap: 10,
      pagination: false,
      breakpoints: {
        768: {
          perPage: 4,
        },
        680: {
          perPage: 3,
        },
        480: {
          perPage: 2,
        },
      },
    }).mount();
  }
  const cards = document.getElementsByClassName("splide-cards");
  for (let i = 0; i < cards.length; i++) {
    new Splide(cards[i], {
      lazyLoad: "nearby",
      perPage: 3,
      gap: 30,
      pagination: false,
      breakpoints: {
        1023: {
          perPage: 2,
        },
        640: {
          perPage: 1,
        },
      },
    }).mount();
  }
});
